<template>
  <div>
    <b-card>
      <b-row>

        <b-col
          cols="12"
        >
          <title-text />
        </b-col>
        <b-col cols="12">
          <date-cards />
        </b-col>
        <b-col cols="12">
          <url-cards />
        </b-col>
        <b-col cols="12">
          <fair-adress-cards />
        </b-col>
        <b-col cols="12">
          <stand-no-cards />
        </b-col>
      </b-row>
    </b-card>
    <image-card />
    <image-content-cards />
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'

import TitleText from '@/views/Admin/Fairs/elements/Title.vue'
import FairAdressCards from '@/views/Admin/Fairs/elements/FairAdressCard.vue'
import StandNoCards from '@/views/Admin/Fairs/elements/StandNoCards.vue'
import ImageCard from '@/views/Admin/Fairs/elements/Image.vue'
import ImageContentCards from '@/views/Admin/Fairs/elements/ImageContent.vue'
import DateCards from '@/views/Admin/Fairs/elements/DateCards.vue'
import UrlCards from '@/views/Admin/Fairs/elements/Url.vue'

export default {
  name: 'ItemForm',
  components: {

    BCard,
    BRow,
    BCol,
    BButton,
    TitleText,
    FairAdressCards,
    StandNoCards,
    ImageCard,
    ImageContentCards,
    DateCards,
    UrlCards,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['fairs/dataItem']
    },
  },
}
</script>
